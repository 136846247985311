import gql from 'graphql-tag'
import chatFragment from './ChatFragment'

export const CHATS_QUERY = gql`
  query getChatMessage($room: String) {
    chats: getChatMessage(room: $room) {
      ...${chatFragment}
    }
  }
`
export const CHATS_QUERY_OUT = gql`
query getChatMessageSuport($room: String) {
  chats: getChatMessageSuport(room: $room) {
    ...${chatFragment}
  }
}
`

export const CHATS_ROOM_QUERY = gql`
  query getReciveMessagePartner($testID:String) {
    recivedChats: getReciveMessagePartner(testID:$testID) {
      room :name
      testID: test
      flag
      messages{
        body
        time
        read
        date
        flag_to
        author{
          id
          name
          photo
        }
        to{
          id
          name
          photo
        }
      }
    }
  }
`
export const CHATS_QUERY_OUT_LIST = gql`
  query getReciveMessageSuportEntity($id: ID) {
    chatsWL: getReciveMessageSuportEntity(id: $id){
       room :name
      testID: test
      from
      messages{
        body
        time
        read
        date
        flag_to
        author{
          id
          name
          photo
        }
        to{
          id
          name
          photo
        }
      }
    }
  }
`
export const CHATS_ROOM_QUERY_UPD = gql`
  query getReciveMessageSuport {
    getReciveMessageSuport {
      room :name
      testID: test
      from
      messages{
        body
        time
        read
        date
        flag_to
        author{
          id
          name
          photo
        }
      }
    }
  }
`
// export const CHECK_CHAT_SUPORT = gql`
//   query checkSuportEntity {
//      checkSuportEntity {
//       flag
//     }
//   }
// `
export const CHECK_CHAT_SUPORT = gql `
  query checkSuportMessage($id: ID) {
    checkSuportMessage(id:$id) {
      flag
    }
  }
`