<template>
  <v-hover v-slot:default="{ hover }" open-delay="200">
    <v-card :elevation="hover ? 16 : 2" max-width="480" max-height="480">
      <v-card
        flat
        fluid
        @click="select ? $emit('select', test) : $emit('preview', test)"
      >
        <v-overlay v-if="selectedTests" absolute :value="selected(test)">
          <v-icon class="primary--text" x-large>mdi-check</v-icon>
        </v-overlay>
        <div style="height:150px">
          <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            :src="
              test.image ? `${apiUrl}/images/test/${test.image}` : `/teste.jpeg`
            "
            height="150px"
          >
            <v-system-bar window flat color="rgba(0, 0, 0, 0)" dense>
              <v-menu v-if="test.user.id === currentUser.id" bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

               <v-list dense>
                  <v-list-item
                    @click.prevent="$emit('edit', Object.assign({}, test))"
                  >
                    <v-list-item-title>{{$t('editTest')}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('delete', test.id)"> 
                    <v-list-item-title>{{$t('remove')}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <strong
                v-if="
                  test.entity && test.creatorType != 'entity' && test.status
                "
                class="success--text"
                >{{$t('approved')}}</strong
              >
              <strong
                v-else-if="test.entity && test.creatorType != 'entity'"
                class="error--text"
                >{{$t('pending')}}</strong
              >

              <v-spacer></v-spacer>

              <v-chip dark small label class="text-capitalize">
                {{ $t(test.type) }}
              </v-chip>
            </v-system-bar>
          </v-img>
        </div>

        <v-card-text>
          <!-- <div class="text-capitalize" style="font-size: 12px">{{ test.type }}</div> -->
          <!-- <pre>{{test.title.length}}</pre> -->
          <div
            v-if="test.title.length >= 36"
            class="body black--text" style="font-size: 12pt"
          >
            {{ test.title }}
          </div>

          <div v-else class="body black--text" style="font-size: 12pt">
            {{ test.title }}
          </div>

          <div
            class="my-2 px-0"
            style="font-size: 12px"
          >
            {{$t('test_by')}} {{ getUserName(test.user.name) }}
            <!-- <pre>{{test.user}}</pre> -->
          </div>

          <p
            v-if="test.description.length > 45"
            class="black--text  py-1"
            style="font-size: 14px; height:50px"
          >
            {{
              test.description ? test.description.substring(0, 45) + "..." : ""
            }}
          </p>
          
          <p
            v-else
            class="black--text "
            style="font-size: 14px; height:50px"
          >
            {{ test.description ? test.description : "" }}
          </p>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-row class="d-flex flex-row justify-end align-center pa-5">
          <v-btn small color="primary">
            <v-icon small>mdi-cart</v-icon>
            {{
              test.price || test.price > 0
                ? formatCurrency(test.price)
                : $t('Free')
            }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="select"
            color="primary"
            text
            small
            @click="$emit('preview', test)"
          >
            {{$t('view')}}
          </v-btn>
          <v-btn v-else icon small>
            <v-icon small color="black">mdi-share-variant</v-icon>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { API_URL } from "@/api";
import { mapGetters } from "vuex";
import formatCurrencyMixins from "@/mixins/format-currency";
export default {
  name: "TestCard",
  props: {
    selectedTests: Array,
    test: Object,
    select: Boolean,
  },
  mixins: [formatCurrencyMixins],
  data: () => ({
    apiUrl: API_URL,
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    getUserName(name) {
      name = name.split(" ");
      return name[0];
    },
    selected(test) {
      const index = this.selectedTests.findIndex((t) => t.id == test.id);
      return index > -1;
    },
  },
};
</script>
