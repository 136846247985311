import gql from 'graphql-tag'
import chatFragment from './ChatFragment'


export const SEND_MESSAGE = gql `
mutation sendMessage($nameRoom:String, $body:String, $time:String, $date:String,$testID: String, $to:ID,$flag:String){
    sendMessage( nameRoom:$nameRoom, body:$body , time:$time, date:$date,testID:$testID,to:$to,flag:$flag){
        ...${chatFragment}
  }
}
`

export const SEND_MESSAGE_OUT = gql `
mutation sendMessageOutSide($nameRoom:String, $body:String, $time:String, $date:String,$testID: String, $to:ToInput,$flag:String,$author: AuthorInput, $from:String){
  sendMessageOutSide( nameRoom:$nameRoom, body:$body , time:$time, date:$date,testID:$testID,to:$to,flag:$flag, author:$author, from:$from){
        ...${chatFragment}
  }
}`



export const UPDATE_MESSAGE = gql`
  mutation updateMessage($room: String){
  	updateMessage(room:$room)
  }
`
export const UPDATE_MESSAGE_OUT = gql`
  mutation updateMessageOutSide($room: String,$author: AuthorInput){
  	updateMessageOutSide(room:$room,author:$author)
  }
`